import axios from "axios";
// const axios = require("axios");

const fetchData = (displayElement, filterKey, filterValue) => {
  console.log("fetchData is called with:", {
    displayElement,
    filterKey,
    filterValue,
  });
  let url =
    "https://UHTUZ4Y6Q8TP35IEURBMPV25YU22PF4V@dev.1001coffres.com/api/products";
  let output_format = "JSON";
  let UserName = "VUhUVVo0WTZROFRQMzVJRVVSQk1QVjI1WVUyMlBGNFY6";
  let displayValue = displayElement ? displayElement : "full";

  let apiUrl = `${url}?output_format=${output_format}&display=${displayValue}`;

  if (filterKey && filterValue) {
    apiUrl += `&filter[${filterKey}]=[${filterValue}]`;
  }

  console.log("Apiurl is:", apiUrl);
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl,
    headers: {
      Authorization: `Basic ${UserName}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export default fetchData;

// exemple d'appel fonction
// fetchData("full", "id_category_default", "83");
