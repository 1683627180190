import "./App.css";
import fetchData from "./API";
import { useState } from "react";

function App() {
  const [dataCoffreFort, setDataCoffreFort] = useState([]);
  const [dataArmoireForte, setDataArmoireForte] = useState([]);
  const [dataBoiteAClefs, setDataBoiteAClefs] = useState([]);
  const [dataArmoireAFusils, setDataArmoireAFusils] = useState([]);
  const dataCategories = {
    83: setDataCoffreFort,
    92: setDataArmoireForte,
    91: setDataArmoireAFusils,
    153: setDataBoiteAClefs,
  };
  const handleFetchData = (displayElement, filterKey, filterValue) => {
    fetchData(displayElement, filterKey, filterValue)
      .then((data) => {
        console.log("data:", data);
        const setter = dataCategories[filterValue];
        if (setter) {
          setter(data);
        } else {
          console.error(`no state setter found for filtervalue`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="App flex justify-center h-screen items-center">
      <button
        className="btn"
        onClick={() => {
          handleFetchData("full", "id_category_default", "83");
        }}
      >
        Coffre Fort
      </button>
      <button
        className="btn"
        onClick={() => {
          handleFetchData("full", "id_category_default", "92");
        }}
      >
        Armoire Forte
      </button>
      <button
        className="btn"
        onClick={() => {
          handleFetchData("full", "id_category_default", "91");
        }}
      >
        Armoire à Fusils
      </button>
      <button
        className="btn"
        onClick={() => {
          handleFetchData(null, "id_category_default", "153");
        }}
      >
        Boite à clefs
      </button>
    </div>
  );
}

export default App;
